<template>
  <div class="pageV">
    <div class="header">
      <van-nav-bar
        :title="$t('chongzhi')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>

    <div class="centV">
      <van-tabs color="#f0b82d" @click="onTabClick" :ellipsis="false">
        <van-tab
          :title="
            item.wallet_type
              ? item.product_type + '/' + item.wallet_type
              : item.product_type
          "
          v-for="(item, index) in tabList"
          :key="index"
        >
          <div v-if="false">
            <div class="topV">
              <span style="color: rgba(255, 255, 255, 0.85); font-size: 1rem;">
                {{ $t("AccountBalance") }}
              </span>
              <van-row>
                <span style="color: #fff; font-size: 2rem;">{{
                  $t("fuhao")
                }}</span>
                <span style="color: #fff; font-size: 2rem;margin-left: 0.5rem;">
                  {{ balance }}
                </span>
              </van-row>
            </div>

            <div class="cententV">
              <span class="inputTitle">{{ $t("RechargeAmount") }}</span>
              <div class="inputV">
                <span style="color: #333; font-size: 1rem;">{{
                  $t("fuhao")
                }}</span>
                <van-field
                  :placeholder="$t('PleaseAmount')"
                  type="number"
                  v-model="money"
                  @input="onInputUd"
                  clearable
                ></van-field>
              </div>
              <van-grid :column-num="4" :border="false">
                <van-grid-item v-for="(item, index) in moneyList" :key="index">
                  <span
                    @click="onItemMoneyClick(item, index)"
                    :class="moneyPos == index ? 'moneyItemTo' : 'moneyItem'"
                    >{{ $t("fuhao") }}{{ item }}</span
                  >
                </van-grid-item>
              </van-grid>
            </div>

            <span style="height: 0.62rem; background-color: #f7f7f7;"></span>
            <span class="cardV">{{ $t("PaymentMethod") }}</span>

            <div
              @click="onItemPayClick(payItem, payIndex)"
              class="cardItem"
              v-for="(payItem, payIndex) in thirdList"
              :key="payIndex"
            >
              <img
                style="border-radius: 50%; width: 17px;height: 17px;margin-right: 3px;"
                :src="payItem.logo"
              />
              <span v-if="lang == 'en'"> {{ payItem.en_name }}</span>
              <span v-if="lang == 'ru'"> {{ payItem.ru_name }}</span>
              <span v-if="lang == 'zh'"> {{ payItem.zh_name }}</span>
              <span v-if="lang == 'fr'"> {{ payItem.fr_name }}</span>
              <span v-if="lang == 'it'"> {{ payItem.it_name }}</span>
              <span v-if="lang == 'es'"> {{ payItem.es_name }}</span>
              <img
                v-if="payPos == payIndex"
                src="../../assets/img/selete_.png"
              />
              <img v-else src="../../assets/img/selete.png" />
            </div>

            <div style="margin-left: 1rem; margin-right: 1rem;">
              <van-button
                class="confirmTv"
                :loading="loading"
                :loading-text="$t('jia-zai-zhong')"
                @click="submit"
              >
                {{ $t("Confirm") }}
              </van-button>
            </div>

            <span class="cardV">{{ $t("RechargeInstructions") }}</span>

            <div class="htmlTv">
              <p v-html="txtHtml"></p>
            </div>
          </div>
          <div v-else>
            <img class="qrIv" :src="item.wallet_image" />
            <div class="addressV">
              <span class="addressTv">{{ item.wallet_url }}</span>
              <img
                class="addressIv"
                src="../../assets/img/copy2.svg"
                @click.prevent="onCopyClick()"
                :data-clipboard-text="item.wallet_url"
              />
            </div>
            <div class="imageV">
              <span class="imageTv">{{ $t("TransactionScreenshot") }}</span>
              <van-uploader :after-read="afterRead">
                <div class="carmV">
                  <img
                    v-if="!imageUrl"
                    class="carmIv"
                    src="../../assets/img/xiangji.svg"
                  />
                  <span v-if="!imageUrl" class="carmTv">
                    {{ $t("UploadImage") }}
                  </span>

                  <img
                    :src="imageUrl ? imageUrl : ''"
                    ref="imageUrl"
                    v-if="imageUrl"
                    style="max-width:100%;max-height:10rem;text-align:center"
                  />
                </div>
              </van-uploader>
            </div>
            <div style="margin-left: 1rem; margin-right: 1rem;">
              <div class="subTv" @click="onSubClick(item)">
                {{ $t("common.submit") }}
              </div>
            </div>
            <span class="cardV">{{ $t("RechargeInstructions") }}</span>

            <div class="htmlTv">
              <p v-html="txtHtml"></p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-popup
      :close-on-click-overlay="false"
      v-model="showDialog"
      style="background-color:transparent; overflow:hidden;"
    >
      <div class="loadingWrap">
        <van-loading v-if="showDialog" type="spinner" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { get } from "echarts/lib/CoordinateSystem";

export default {
  data() {
    return {
      lang: localStorage.getItem("lang"),
      money: "",
      txtHtml: "",
      balance: "",
      moneyList: [],
      moneyPos: -1,
      payPos: -1,
      thirdList: [],
      loading: false,
      tabList: [],
      imageUrl: "",
      showDialog: false,
    };
  },
  created() {
    this.getTabList();
    this.getMoney();
    this.getPayTxt();
    this.getinfo();
    this.getPayThird();
  },
  methods: {
    async getMoney() {
      if (this.lang == "en") {
        this.moneyList = [1000, 2000, 3000, 5000, 10000, 20000, 50000, 100000];
      } else if (this.lang == "ru") {
        this.moneyList = [
          1000,
          2000,
          3000,
          10000,
          50000,
          100000,
          200000,
          300000,
        ];
      } else if (this.lang == "zh") {
        this.moneyList = [
          2000,
          5000,
          10000,
          50000,
          100000,
          300000,
          500000,
          1000000,
        ];
      } else if (this.lang == "fr") {
        this.moneyList = [
          2000,
          5000,
          10000,
          50000,
          100000,
          300000,
          500000,
          1000000,
        ];
      } else if (this.lang == "it") {
        this.moneyList = [50, 100, 200, 500, 1000, 2000, 5000, 10000];
      } else if (this.lang == "es") {
        this.moneyList = [500, 1000, 2000, 5000, 10000, 50000, 100000, 200000];
      }
    },
    //获取类型
    async getTabList() {
      const { data } = await this.$http.get("/home/user/walletlist");
      if (data) {
        if (data.code === 200) {
          this.tabList = [];
          let dte = {
            product_type: this.$t("BankCard"),
            wallet_type: "",
          };
          // this.tabList.push(dte);
          this.tabList.push(...data.data.list);
        }
      }
    },
    // 图片上传
    async afterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      const { data } = await this.$http.post("/home/user/uploadimg", param);
      if (data) {
        if (data.code === 200) {
          this.imageUrl = data.data.url;
        } else {
          this.$toast(data.msg);
        }
      }
    },
    //获取余额
    async getinfo() {
      const { data } = await this.$http.get("/home/user/withdraw");
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance;
        }
      }
    },
    //获取充值说明
    async getPayTxt() {
      const { data } = await this.$http.get("/home/index/payText?id=1");
      if (data) {
        if (data.code === 200) {
          if (this.lang == "en") {
            this.txtHtml = data.data[0].en_content;
          } else if (this.lang == "ru") {
            this.txtHtml = data.data[0].ru_content;
          } else if (this.lang == "zh") {
            this.txtHtml = data.data[0].zh_content;
          } else if (this.lang == "fr") {
            this.txtHtml = data.data[0].fr_content;
          } else if (this.lang == "it") {
            this.txtHtml = data.data[0].it_content;
          } else {
            this.txtHtml = data.data[0].es_content;
          }
        }
      }
    },
    //获取充值通道
    async getPayThird() {
      const { data } = await this.$http.get("/home/pay/payThird");
      if (data) {
        if (data.code === 200) {
          this.thirdList = data.data;
        }
      }
    },
    //复制
    onCopyClick() {
      const clipboard = new Clipboard(".addressIv");
      clipboard.on("success", (e) => {
        this.$toast.success(this.$t("common.success"));
      });
      clipboard.on("error", (e) => {
        console.log("------", JSON.stringify(e));
      });
    },
    //提交
    async onSubClick(item) {
      if (!this.imageUrl) {
        this.$toast(this.$t("PleaseSelectAPicture"));
        return;
      }
      this.showDialog = true;
      let postform = {
        wallet_id: item.id,
        img: this.imageUrl,
      };
      const { data } = await this.$http.post(
        "/home/user/coinRecharge",
        postform
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("czhi.submitok"));
          this.$router.go(-1);
        } else {
          this.$message.error(this.$t("common.error"));
        }
      }
      this.showDialog = false;
    },
    //切换
    onTabClick(e) {
      this.imageUrl = "";
    },
    onItemMoneyClick(item, index) {
      this.moneyPos = index;
      this.money = item;
    },
    onInputUd(e) {
      let pos = -1;
      this.moneyList.map((item, index) => {
        if (e == item) {
          pos = index;
        }
      });
      this.moneyPos = pos;
    },
    onItemPayClick(item, index) {
      this.payPos = index;
    },
    async submit() {
      if (this.money.length == 0) {
        this.$toast.fail(this.$t("PleaseAmount"));
        return false;
      }
      if (this.payPos == -1) {
        this.$toast.fail(this.$t("PleaseSelectaRechargeChannel"));
        return false;
      }
      this.loading = true;
      let postform = {
        price: this.money,
        third_id: this.thirdList[this.payPos].id,
      };
      const { data } = await this.$http.post("/home/pay/pay", postform);
      if (data) {
        if (data.code === 200) {
          // window.location.href = data.data;
          this.$toast.success(this.$t("czhi.submitok"));
          this.$router.go(-1);
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.pageV {
  display: flex;
  flex-direction: column;
}

.header {
  box-shadow: none;
}
.centV {
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  background: #fff;

  .topV {
    width: 100%;
    height: 6.625rem;
    background-image: url("../../assets/img/top_bg.png");
    background-size: 100% 100%;
    padding-left: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }
}

.cententV {
  // height: 15rem;
  background-color: #fff;
  border-radius: 5px 5px 0px 0px;
  margin-top: -0.5rem;
  padding: 1rem;
  box-sizing: border-box;
  text-align: start;

  .inputTitle {
    color: #333;
    font-size: 1rem;
    font-family: "Alibaba PuHuiTi";
    font-style: normal;
    font-weight: 700;
  }

  .inputV {
    height: 2.81rem;
    background: #f6f6f6;
    border-radius: 0.43rem;
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-left: 0.62rem;
    padding-right: 0.62rem;
    font-size: 1rem;
    margin-bottom: 0.63rem;

    .van-field {
      color: #333;
      font-size: 1rem;
      background-color: #00000000;

      input {
        color: #333;
      }
    }
  }
}

.moneyItem {
  width: 4.87rem;
  height: 3.12rem;
  background: #f6f6f6;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.62rem;
  color: #363636;
  font-size: 14px;
  border: 1px solid #f6f6f6;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
}

.moneyItemTo {
  width: 4.87rem;
  height: 3.12rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.62rem;
  color: #363636;
  font-size: 14px;
  background: rgba(10, 81, 161, 0.05);
  border: 1px solid #363636;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
}

/deep/.van-grid-item__content {
  padding: 0px 0px;
}

.cardV {
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
  text-align: start;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.62rem;
  color: #333;
  font-size: 1rem;
  display: flex;
}

.cardItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.62rem;
  padding-bottom: 0.62rem;
  padding-left: 1rem;
  padding-right: 1rem;

  span {
    width: 90%;
    color: #333;
    font-size: 0.93rem;
    overflow: hidden;
    word-break: break-all;
    text-align: start;
  }

  img {
    width: 1rem;
    height: 1rem;
  }
}

.confirmTv {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.12rem;
  color: #fff;
  height: 2.75rem;
  background: #363636;
  border-radius: 0.2rem;
}

.htmlTv {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: start;
}

.qrIv {
  width: 123px;
  height: 123px;
  margin-top: 20px;
}

.addressV {
  display: flex;
  align-items: center;
  margin-top: 23px;
  padding-left: 16px;
  padding-right: 16px;
}

.addressTv {
  font-size: 15px;
  color: #f0b82d;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  max-width: 90%;
  overflow: hidden;
  word-break: break-all;
}

.addressIv {
  width: 13px;
  height: 13px;
  margin-left: 10px;
}

.imageV {
  display: flex;
  padding-left: 16px;
  margin-top: 23px;
}

.imageTv {
  font-size: 14px;
  color: #aaa;
}

.img-btn {
  font-size: 1.6rem;
  color: #999;
  padding: 3px 8px;
}

.subTv {
  width: 100%;
  height: 43px;
  background-color: #f0b82d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 33px;
}

.carmV {
  width: 80px;
  height: 80px;
  background: #f7f8fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.carmIv {
  width: 22px;
  height: 22px;
}

.carmTv {
  color: #858a8f;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
}
/deep/.van-tab--active {
  color: #f0b82d !important;
}
</style>
